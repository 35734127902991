import React, { createContext, useState } from 'react';

const defaultValues = {
  isOverlayActive: false,
  setOverlayActive: () => {},

  isCallbackOpen: false,
  toggleCallbackOpen: () => {},
};

export const SiteContext = createContext(defaultValues);

export const SiteProvider = ({ children }) => {
  const [isOverlayActive, setOverlayActive] = useState(
    defaultValues.isOverlayActive,
  );
  const [isCallbackOpen, setCallbackOpen] = useState(
    defaultValues.isCallbackOpen,
  );

  const toggleCallbackOpen = () => {
    setCallbackOpen(!isCallbackOpen);
  };

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,

        isOverlayActive,
        setOverlayActive,

        isCallbackOpen,
        toggleCallbackOpen,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
